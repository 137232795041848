<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        md="10"
        lg="8"
        xl="6"
      >
        <v-card class="content-card-padding">
          <template v-if="project">
            <p class="title">
              {{ project.title }}
            </p>
            <label class="subtitle d-block mt-1 mb-4">
              The comments of the project appear on the project
              page and visible to all Portal users.
              You can only add comments to the project in the Portal.
            </label>
          </template>
          <v-divider class="mb-5" />
          <p>Comments ({{ comments.length }})</p>
          <label v-if="!comments.length">There are no comments added to the project yet.</label>
          <project-card-item
            v-for="comment in comments"
            :key="comment.id"
            class="mb-2"
            :item="comment"
            item-type="comment"
            @delete-item="removeComment"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import projectService from '@/services/projectService';
import ProjectCardItem from './ProjectCardItem';
import commentService from '@/services/commentService';

export default {
  name: 'ManageProjectComments',
  components: {
    ProjectCardItem
  },
  props: {
    projectId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      project: null,
      comments: []
    };
  },
  async created() {
    this.project = await projectService.fetchProjectById(this.projectId);
    const reactionBoard = await commentService.getReactionBoard(this.projectId);
    this.comments = reactionBoard ? reactionBoard.comments.map((c) => ({
      id: c.id,
      creator: c.name,
      description: c.content,
      date: c.date
    })) : [];
  },
  methods: {
    async removeComment(comment) {
      await commentService.removeComment(this.projectId, comment.id);
      this.comments = this.comments.filter((c) => c.id !== comment.id);
      this.$store.dispatch('showSnackBar', { text: 'The comment was removed' });
    }
  }
};
</script>
