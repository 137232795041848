<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        md="10"
        lg="8"
        xl="6"
      >
        <v-card class="content-card-padding">
          <template v-if="project">
            <p class="title">
              {{ project.title }}
            </p>
            <label class="title">Coaching Materials</label>
            <label class="subtitle d-block mt-1 mb-4">Share materials with the project team</label>
            <manage-coaching-material
              v-if="showManageCoachingMaterial"
              :show="showManageCoachingMaterial"
              :coaching-material="currentCoachingMaterial"
              @submit="manageCoachingMaterial"
              @cancel="closeModal"
            />
            <div
              v-if="coachingMaterials.length"
              class="coaching-materials-container"
            >
              <project-card-item
                v-for="(item, index) in coachingMaterials"
                :key="index"
                :item="item"
                :edit-mode="true"
                class="mb-2"
                @delete-item="removeCoachingMaterials"
                @edit-item="editCoachingMaterial(item)"
              >
                <v-card-text class="py-1">
                  <a
                    :href="item.url"
                    target="_blank"
                    class="font-weight-medium"
                  >{{ item.url }}</a>
                </v-card-text>
              </project-card-item>
            </div>
            <v-btn
              color="primary"
              class="add-coaching-button"
              outlined
              @click="showManageCoachingMaterial = true"
            >
              + Add coaching materials
            </v-btn>
            <v-checkbox
              v-model="shareCoachingMaterial"
              label="Notify team via e-mail"
              color="primary"
            />
            <v-btn
              color="primary"
              class="mb-6 font-weight-bold save-button"
              @click="saveCoachingMaterials"
            >
              Save
            </v-btn>
          </template>
          <label class="title d-block">Coaching Notes</label>
          <label class="subtitle d-block mt-1 mb-4">
            Take notes about the Kickbox project and also share them with the project team
          </label>
          <editor
            ref="editor"
            :content="editorContent"
            :submit-button-text="submitText"
            toolbar="minimalToolbar"
            @submit="manageNote"
            @keyUp="editorContent = $event"
          >
            <v-radio-group
              v-model="isLearningNote"
              column
              hide-details
            >
              <v-radio
                label="Private (visible only to admins)"
                :value="false"
              />
              <v-radio
                label="Public (visible to KICKBOX project team and admins)"
                :value="true"
              />
            </v-radio-group>
          </editor>
          <div
            v-if="notes.length > 0"
            class="notes-container"
          >
            <p>All notes ({{ notes.length }})</p>
            <project-card-item
              v-for="item in notes"
              :key="item.id"
              :item="item"
              :edit-mode="true"
              class="mb-2"
              item-type="note"
              @delete-item="deleteNote"
              @edit-item="editNote"
            />
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment';
import { Editor } from '@kickbox/common-admin';
import { randomPassword } from '@kickbox/common-util';
import UserService from '@/services/userService';
import NoteService from '@/services/noteService';
import projectService from '@/services/projectService';
import ProjectCardItem from '@/components/projects/ProjectCardItem';
import ManageCoachingMaterial from '@/components/projects/ManageCoachingMaterial';

export default {
  name: 'ManageProjectNotes',
  components: {
    Editor,
    ManageCoachingMaterial,
    ProjectCardItem
  },
  props: ['id'],
  data() {
    return {
      editorContent: '',
      notes: [],
      coachingMaterials: [],
      originalCoachingMaterials: [],
      editMode: false,
      editItem: null,
      isLearningNote: false,
      project: null,
      shareCoachingMaterial: true,
      currentCoachingMaterial: null,
      showManageCoachingMaterial: false
    };
  },
  computed: {
    submitText() {
      return this.editMode ? 'Update' : 'Add Note';
    },
    currentProjectId() {
      return this.$route
      && this.$route.params
      && this.$route.params.id ? this.$route.params.id : null;
    }
  },
  async created() {
    if (await this.initialize()) {
      this.notes = [...this.project.notes];
    } else {
      // The project is not found, redirecting to neutral page
      this.$router.push({ name: 'StatisticDashboard' });
    }
  },
  methods: {
    async initialize() {
      this.project = this.$store.getters.projectById(this.id);
      const projectNotes = (this.project && this.project.notes) || [];
      const validNote = projectNotes.some((note) => note.creator && note.description);
      if (!this.project || !validNote) {
        this.project = await projectService.fetchProjectById(this.currentProjectId);
      }
      if (!this.project) {
        return false;
      }
      this.coachingMaterials = this.project.coachingMaterials.map((cm, index) => ({
        ...cm,
        id: index + 1
      }));
      this.originalCoachingMaterials = this.$lodash.cloneDeep(this.coachingMaterials);
      return true;
    },
    manageNote(content) {
      if (content) {
        const note = {
          description: content,
          isLearningNote: this.isLearningNote,
          creator: UserService.getCurrentUserName(),
          date: this.date(),
          projectId: this.id
        };
        if (!this.editMode) {
          NoteService.addProjectNote(note, note.projectId).then((addedNote) => {
            this.notes.unshift(this.formatNote(addedNote));
          });
        } else {
          NoteService
            .editProjectNotes(this.editItem.id, this.editorContent, this.isLearningNote)
            .then((editedNote) => {
              this.notes = this.notes.map((n) => (n.id === editedNote.id
                ? Object.assign(n, this.formatNote(editedNote)) : n));
            });
          this.editMode = false;
        }
        if (this.project) {
          this.$store.commit('updateProject', Object.assign(this.project, {
            notes: this.notes
          }));
        }
        this.editorContent = '';
        this.$refs.editor.clearContent();
      }
    },
    deleteNote(note) {
      const index = this.notes.indexOf(note);
      NoteService.removeProjectNotes(note.id).then(() => {
        this.notes.splice(index, 1);
        if (this.project) {
          this.$store.commit('updateProject', Object.assign(this.project, { notes: this.notes }));
        }
        this.$store.dispatch('showSnackBar', { text: 'The note was removed' });
      });
    },
    editNote(item) {
      this.editMode = true;
      this.editItem = item;
      this.editorContent = item.description;
      this.isLearningNote = item.isLearningNote;
      this.$vuetify.goTo('.save-button');
    },
    date() {
      return moment(new Date()).format('h:mm A MMM DD, YYYY');
    },
    formatNote(note) {
      return {
        id: note.id,
        creator: note.get('creator'),
        description: note.get('description'),
        isLearningNote: note.get('isLearningNote'),
        date: note.get('date'),
        parseObject: note,
      };
    },
    formatCoachingMaterial(item) {
      return {
        title: item.title,
        url: item.url,
        date: this.date(),
        creator: UserService.getCurrentUserName()
      };
    },
    manageCoachingMaterial(item) {
      this.closeModal();
      if (!item.id) {
        this.coachingMaterials.push({
          ...this.formatCoachingMaterial(item),
          id: randomPassword(5)
        });
      } else {
        this.coachingMaterials = this.coachingMaterials.map((cm) => (cm.id === item.id
          ? { ...cm, ...this.formatCoachingMaterial(item) } : cm));
      }
    },
    async saveCoachingMaterials() {
      this.coachingMaterials = this.coachingMaterials.map((cm) => ({
        title: cm.title,
        url: cm.url,
        date: cm.date,
        creator: cm.creator
      }));
      await projectService
        .updateProject(this.project.id, { coachingMaterials: this.coachingMaterials });
      const updateProject = { ...this.project, coachingMaterials: this.coachingMaterials };
      this.$store.commit('updateProject', updateProject);
      if (this.shareCoachingMaterial
          && this.coachingMaterials.length >= this.originalCoachingMaterials.length) {
        NoteService.sendCoachingMaterialEmail(this.id);
      }
      await this.initialize();
      return this.$store.dispatch('showSnackBar', { text: 'The Coaching Materials were saved' });
    },
    removeCoachingMaterials(coaching) {
      this.coachingMaterials = this.coachingMaterials.filter((item) => item.id !== coaching.id);
    },
    editCoachingMaterial(item) {
      this.currentCoachingMaterial = item;
      this.showManageCoachingMaterial = true;
    },
    closeModal() {
      this.currentCoachingMaterial = null;
      this.showManageCoachingMaterial = false;
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .add-coaching-button .v-btn__content {
  text-transform: initial;
  letter-spacing: initial;
}
.notes-container {
  ::v-deep .list-format {
    margin: 1rem 0;
  }
}
.coaching-materials-container {
  ::v-deep .list-format {
    margin-bottom: 1.5rem;
  }
  ::v-deep .col {
    padding-top: 0;
  }
}
</style>
