<template>
  <v-dialog
    :value="show"
    max-width="550"
    persistent
  >
    <v-card>
      <v-card-title>{{ titleLabel }}</v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col
            cols="11"
          >
            <v-text-field
              v-model="title"
              clearable
              placeholder="Title*"
            />
          </v-col>
          <v-col cols="11">
            <v-text-field
              v-model="url"
              clearable
              placeholder="Url*"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pb-4 pr-4">
        <v-spacer />
        <app-button
          color="error"
          secondary
          class="mr-2"
          @click="$emit('cancel')"
        >
          Cancel
        </app-button>
        <app-button
          :disabled="!title.trim() || !url.trim()"
          @click="submit"
        >
          {{ buttonText }}
        </app-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ManageCoachingMaterial',
  props: {
    coachingMaterial: {
      type: Object,
      default: () => ({})
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      title: '',
      url: '',
      newCoachingMaterial: true
    };
  },
  computed: {
    buttonText() {
      return this.newCoachingMaterial ? 'add' : 'update';
    },
    titleLabel() {
      const type = this.newCoachingMaterial ? 'Add' : 'Edit';
      return `${type} coaching material`;
    }
  },
  created() {
    if (this.coachingMaterial) {
      this.title = this.coachingMaterial.title;
      this.url = this.coachingMaterial.url;
      this.newCoachingMaterial = false;
    }
  },
  methods: {
    getValidUrl(url) {
      if (url && !url.startsWith('http')) {
        return `https://${url}`;
      }
      return url;
    },
    submit() {
      this.$emit('submit', {
        title: this.title,
        url: this.getValidUrl(this.url),
        id: this.coachingMaterial ? this.coachingMaterial.id : null
      });
    }
  }
};
</script>
