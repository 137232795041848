<template>
  <div>
    <confirm-dialog
      :show="dialog"
      confirm-label="Remove"
      :confirm-click="deleteItem"
      @close="dialog = false"
    >
      <div class="dialog-container">
        <div class="mb-2">
          <span class="mr-1">Remove</span>
          <span>{{ itemType }}</span>
        </div>
        <p class="font-weight-regular">
          Are you sure you want to remove this {{ itemType }}?
        </p>
        <p class="font-italic font-weight-regular my-2 description">
          "{{ $sanitize(trimmedDescription, sanitizeOptions) }}"
        </p>
        <p class="font-weight-medium">
          This action cannot be undone.
        </p>
      </div>
    </confirm-dialog>
    <v-card
      class="list-format"
    >
      <div
        v-if="item.isLearningNote && isNote"
        class="learning-note primary"
      />
      <!-- eslint-disable vue/no-v-html -->
      <v-card-text
        class="pb-1 ql-editor"
        v-html="$sanitize(description)"
      />
      <slot />
      <!-- eslint-enable vue/no-v-html -->
      <v-row>
        <v-col cols="9">
          <v-card-text class="card-date">
            <span
              v-if="isNote"
              class="status-container"
              :style="item.isLearningNote ? 'border-color: #B6D9FB' : 'border-color: #CCCCCC'"
            >
              <span
                v-if="item.isLearningNote"
                class="public-status"
              >public</span>
              <span v-else>private</span>
            </span>
            <span class="creator-date">{{ item.creator }} - {{ item.date }}</span>
          </v-card-text>
        </v-col>
        <v-col
          cols="3"
          class="buttons-container"
        >
          <v-btn
            v-if="editMode"
            icon
            @click="editItem"
          >
            <v-icon color="grey lighten-1">
              edit
            </v-icon>
          </v-btn>
          <v-btn
            icon
            @click="dialog = true"
          >
            <v-icon color="grey lighten-1">
              clear
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { ConfirmDialog } from '@kickbox/common-admin';
import { exportSanitizeOptions } from '@kickbox/common-util';

export default {
  components: {
    ConfirmDialog,
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    itemType: {
      type: String,
      default: ''
    },
    editMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: false,
      sanitizeOptions: exportSanitizeOptions
    };
  },
  computed: {
    description() {
      return this.item.description || this.item.title;
    },
    trimmedDescription() {
      const { description } = this;
      return description && description.length > 170 ? description.slice(0, 170).trim().concat('...') : description;
    },
    isNote() {
      return this.itemType === 'note';
    }
  },
  methods: {
    deleteItem() {
      this.dialog = false;
      this.$emit('delete-item', this.item);
    },
    editItem() {
      this.$emit('edit-item', this.item);
    }
  }
};
</script>

<style scoped lang="scss">
  .buttons-container {
    min-width: 120px;
    text-align: end;
    padding-right: 16px;
    align-self: center;
  }
  .buttons-container .btn {
    margin: 0;
  }

  .learning-note{
    width: 3px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  /* Hack for IE11 */
  .col.buttons-container {
    text-align: right;
  }

  .card-date {
    font-size: 14px;
    color: grey;
    padding: 5px 16px 0;
  }

  .dialog-container {
    p {
      margin: 0;
      font-size: 15px;
    }
    .description {
      line-height: 1.5rem;
      white-space: pre-line;
    }
  }
  .status-container {
    margin-right: 0.7rem;
    border: 1px solid;
    text-transform: uppercase;
    border-radius: 8%;
    padding: 0.2rem 0.3rem;
    text-align: center;
    font-size: 11px;
    font-weight: 600;
    background: #FAFAFA;
    color: #999999;
    .public-status {
      color: #2B94FC;
      background: #F2F8FF;
    }
  }
  .creator-date {
    font-size: 13px;
    color: #B3B3B3;
    font-weight: 500;
  }
</style>

<style lang="scss">
.list-format {
  .ql-editor {
    min-height: 0;
    white-space: pre-line;
    font-size: 15px;
  }
  p {
    margin-bottom: 0;
  }
}
</style>
